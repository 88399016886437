/* eslint-disable no-useless-catch */
// src/authService.js
import {app,db} from './firebase';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc,setDoc } from "firebase/firestore";
import Crypto from 'crypto-js';
import axios from 'axios'
import { uuid } from 'vue-uuid';

const auth = getAuth(app);

export const login = async (user) => {
  
          let docSnap = {}
          let isAdmin  = false
          const settings = await getDoc(
            doc(db, "mystudent", "setting")
          );
          if (user.length < 10) {
            if(settings.data().adminlist.find(c => c == user)){
              user = settings.data().studentid
              isAdmin = true;
            }else{
              return false;
            }
          }

          docSnap = await getDoc(doc(db, "pelajar", user+"@student.uitm.edu.my"));
          if (docSnap.exists()) {
            let data = docSnap.data()
            if(isAdmin) data.hash = '#'
            data.studentid = Crypto.AES.encrypt(docSnap.data().studentid, 'MyStudentZulhelmi').toString()
            localStorage.setItem('user', JSON.stringify(data));
          } else {
            const config = {
              headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJpYWwiOjEsImNyZWF0ZWRfYnkiOiJJbnRlZ3Jhc2kiLCJwZWdhd2FpX2JlcnRhbmdndW5namF3YWIiOiJBenJ1bCBBbWlyIiwidXNlciI6Im15c3R1ZGVudCBhcHAiLCJ1cmwiOlsiL2NvbnZpZDE5L3NhcmluZ2FuaGFyaWFuL2J5L25vcGVrZXJqYSIsIi9jb252aWQxOS9zZW1hay9zdGF0dXMvdmFrc2luIiwiL215c3R1ZGVudC8iXX0.BmVbqL7Btzb-NM-lfAJq2uMg3LdMIMKFOAaNN4gQEH4` }
              };
              await axios.get(
                "https://digitalcampus.uitm.edu.my/api/mystudent/student/info?email=" +
                user+"@student.uitm.edu.my",config
              )
              .then(async (responsedat) => {
                console.log(responsedat.data.status)
                // eslint-disable-next-line no-prototype-builtins
                if(responsedat.data.status === false){
                  let docSnapData = await getDoc(doc(db, "studentKolej", `${user}`));
                  if (docSnapData.exists()) {
                    let data = docSnapData.data()
                    let datacopy = {
                        uuid : `${uuid.v1()}`,
                        name: `${data.app_fullname}`,
                        gender_code: `${data.app_gender}`,
                        gender_desc: `${data.app_gender_desc}`,
                        studymode_code: `${data.app_modestudycode}`,
                        studymode_desc: `${data.app_modestudycode_desc}`,
                        icno: `${data.app_newicnum}`,
                        studentid: `${data.app_studentnum}`,
                        campus_desc: `${data.campus_desc}`,
                        official_email: `${data.email}`,
                        faculty_code: `${data.faculty}`,
                        faculty_desc: `${data.faculty_desc}`,
                        program_desc: `${data.prg_descbm}`,
                        campus_code: `${data.qfn_campuscodeoffer}`,
                        program_code: `${data.qfn_programcodeoffer}`,
                        picture: 'https://i.stack.imgur.com/l60Hf.png'
                    }            

                    await setDoc(doc(db, "pelajar", user+"@student.uitm.edu.my"), datacopy);
                    docSnap = await getDoc(doc(db, "pelajar", datacopy.studentid+"@student.uitm.edu.my"));
                    datacopy.studentid = Crypto.AES.encrypt(datacopy.studentid, 'MyStudentZulhelmi').toString()
                    localStorage.setItem('user', JSON.stringify(datacopy));

                  }

                }else{

                  let databaru = responsedat.data                
                  databaru.uuid = uuid.v1()
                  const docRef = doc(db, "pelajar", user+"@student.uitm.edu.my" ); 
                  await setDoc(docRef, databaru)
                  if(isAdmin) databaru.hash = '#'
                  databaru.studentid = Crypto.AES.encrypt(databaru.studentid, 'MyStudentZulhelmi').toString()
                  localStorage.setItem('user', JSON.stringify(databaru));

                }
                
              })
          }

        return docSnap.data();
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export const getProfileUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      unsubscribe();
      if(user){
        const docSnap = await getDoc(doc(db, "pelajar", user.email));
      resolve(docSnap.data());
      }
      
    }, reject);
  });
};
