<template>
    <!-- App Header -->
    <div class="appHeader text-dark">
        <div class="left">
            <!-- <a v-if="$route.name == 'home'" href="#" class="headerButton" @click.prevent="showOffcanvasMenu()"> -->
            <a v-if="$route.name == 'home'" class="headerButton" data-bs-toggle="offcanvas" href="#sidebarPanel">
                <i class="bi bi-list"></i>
            </a>
            <a v-else href="#" class="headerButton" @click.prevent="$router.go(-1)">
                <i class="bi bi-chevron-left"></i>
            </a>
        </div>
        <div class="pageTitle">
            <img src="/img/logomystudent-transparent-tight.png" alt="image" style="max-height: 30px;">
        </div>
        <div class="right" v-if="isAdmin">
            <router-link to="/impersonate" class="headerButton">
                <i class="bi bi-code-slash"></i>
            </router-link>
        </div>
    </div>
    <!-- * App Header -->
    <!-- App Sidebar -->
    <div class="offcanvas offcanvas-start" id="sidebarPanel" tabindex="-1" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-body">
            <!-- profile box -->
            <div class="profileBox">
                <div class="image-wrapper">
                    <img :src="picture" alt="image" class="imaged rounded">
                </div>
                <div class="in">
                    <strong>{{name}}</strong>
                </div>
                <a href="#" class="close-sidebar-button" data-bs-dismiss="offcanvas">
                    <i class="bi bi-x-lg"></i>
                </a>
            </div>
            <!-- * profile box -->

            <ul class="listview flush transparent no-line image-listview mt-2">
                <li>
                    <router-link to="/" class="item">
                        <div class="icon-box bg-primary">
                            <i class="bi bi-house-door"></i>
                        </div>
                        <div class="in">
                            Anjung
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/profile" class="item">
                        <div class="icon-box bg-primary">
                            <i class="bi bi-person"></i>
                        </div>
                        <div class="in">
                            Profil
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/virtual_card" class="item">
                        <div class="icon-box bg-primary">
                            <i class="bi bi-person-badge-fill"></i>
                        </div>
                        <div class="in">
                            Kad Pelajar Digital
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/sponsor" class="item">
                        <div class="icon-box bg-primary">
                            <i class="bi bi-credit-card-2-front"></i>
                        </div>
                        <div class="in">
                            Pendapatan & Penaja
                        </div>
                    </router-link>
                </li>
                <li>
                        <router-link to="/daftar" class="item">
                            <div class="icon-box bg-primary">
                                <i class="bi bi-building"></i>
                            </div>
                            <div class="in">
                                iResidensi
                            </div>
                        </router-link>
                    </li>
                <li>
                        <router-link to="/sulung" class="item">
                            <div class="icon-box bg-primary">
                                <i class="bi bi-box2-heart"></i>
                            </div>
                            <div class="in">
                                Program Pembangunan Siswa SULUNG
                            </div>
                        </router-link>
                    </li>
            </ul>

            <div class="listview-title mt-2 mb-1">
                <span>Settings</span>
            </div>
            <ul class="listview image-listview flush transparent no-line">
                <li>
                    <div class="item">
                        <div class="icon-box bg-primary">
                            <i class="bi bi-moon"></i>
                        </div>
                        <div class="in">
                            <div>Mod Gelap</div>
                            <div class="form-check form-switch">
                                <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodesidebar" @click="modeToggle" v-model="isDark">
                                <label class="form-check-label" for="darkmodesidebar"></label>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li>
                    <div class="item">
                        <div class="icon-box bg-primary">
                            <i class="bi bi-bell"></i>
                        </div>
                        <div class="in">
                            <div>Allow Notification</div>
                            <div class="form-check form-switch">
                                <input class="form-check-input notification-switch" type="checkbox" id="notificationsidebar">
                                <label class="form-check-label" for="notificationsidebar"></label>
                            </div>
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>
        <!-- sidebar buttons -->
        <div class="sidebar-buttons">
            <router-link to="/profile" class="button">
                <i class="bi bi-person"></i>
            </router-link>
            <a @click="reload" class="button">
                <i class="bi bi-arrow-clockwise"></i>
            </a>
            <!-- <a href="#" class="button">
                <i class="bi bi-gear"></i>
            </a> -->
            <a  @click.prevent="logOut" class="button">
                <i class="bi bi-box-arrow-right"></i>
            </a>
        </div>
        <!-- * sidebar buttons -->
    </div>
    <!-- * App Sidebar -->
</template>

<script>
import { logout } from '@/authService';
import { db } from '../../firebase'
import { doc,setDoc, getDoc } from "firebase/firestore"
import { uuid } from 'vue-uuid';
import Crypto from 'crypto-js';
import axios from 'axios'
import { getCurrentUser } from '@/authService';

export default {
    data() {
        return {
            picture: '',
            name: '',
            isAdmin: false,
            papar: false,
            isDark: JSON.parse(localStorage.getItem('darkMode')) || false 
        }
    },
    async mounted(){
        if(this.$store.state.auth.user !== null){
            this.picture = this.$store.state.auth.user?.picture;
            this.name = this.$store.state.auth.user?.name;
        }
        if(this.$store.state.auth.user !== null){
            const settings = await getDoc(
                doc(db, "mystudent", "setting")
            );
            const currUser = await getCurrentUser()
            if (settings.data().adminlist.find(c => c == currUser.email.split("@")[0])) {
                this.isAdmin = true;
            } 
        }
        
    },
    watch: {
        '$route' () {
            document.querySelector('.close-sidebar-button').click()
        }
    },
    
    methods: {
        async logOut() {
            localStorage.removeItem('user');
            await logout();
            //this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
        dark() {
            document.querySelector('body').classList.add('dark-mode')
            this.isDark = true
            localStorage.setItem('darkMode', true);
        },
        async reload() {
            //await setDoc(doc(db, "pelajar",  this.$store.state.auth.user.studentid+"@student.uitm.edu.my"),{ikrar_date:currentDate}, { merge: true })

            const config = {
              headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJpYWwiOjEsImNyZWF0ZWRfYnkiOiJJbnRlZ3Jhc2kiLCJwZWdhd2FpX2JlcnRhbmdndW5namF3YWIiOiJBenJ1bCBBbWlyIiwidXNlciI6Im15c3R1ZGVudCBhcHAiLCJ1cmwiOlsiL2NvbnZpZDE5L3NhcmluZ2FuaGFyaWFuL2J5L25vcGVrZXJqYSIsIi9jb252aWQxOS9zZW1hay9zdGF0dXMvdmFrc2luIiwiL215c3R1ZGVudC8iXX0.BmVbqL7Btzb-NM-lfAJq2uMg3LdMIMKFOAaNN4gQEH4` }
              };
              await axios.get(
                "https://digitalcampus.uitm.edu.my/api/mystudent/student/info?email=" +
                this.$store.state.auth.user.studentid+"@student.uitm.edu.my",config
              )
              .then(async (responsedat) => {
                let databaru = responsedat.data                
                databaru.uuid = uuid.v1()
                const docRef = doc(db, "pelajar", this.$store.state.auth.user.studentid+"@student.uitm.edu.my" ); 
                await setDoc(docRef, databaru)
                databaru.studentid = Crypto.AES.encrypt(databaru.studentid, 'MyStudentZulhelmi').toString()
                localStorage.setItem('user', JSON.stringify(databaru));
                this.$router.go("/");
              })
        },

        light() {
            document.querySelector('body').classList.remove('dark-mode')
            this.isDark = false
            localStorage.setItem('darkMode', false);
        },

        modeToggle() {
            if(this.isDark) {
                this.light()
            } else {
                this.dark()
            }
        },
    },
    setup() {

        let isDark = JSON.parse(localStorage.getItem('darkMode')) || false

        if(isDark){
            document.querySelector('body').classList.add('dark-mode')
        }
    }
}
</script>